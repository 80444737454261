<template>
  <div>
    <li
      @click="$emit('selectDate', day)"
      @mouseover="
        () => {
          $emit('setHoverDate', day);
        }
      "
      :id="day.date"
      class="calendar-day text-center"
      :class="{
        'calendar-day--not-current': !day.isCurrentMonth,
        'calendar-day--today': isToday,
        'calendar-day--less-than-today': isLessThanToday,
        'calendar-day--in-range': isInRange,
        'calendar-day--in-hovered-range': isHoveredInRange,
        'calendar-day--is-selected': isSelect,
        'calendar-day--is-custom-price': day.is_custom_price==1,
        'calender-day--is-peak': dayIsPeak,

      }"
    >
      <div class="text-center">
        {{ label }}

        <div
          class="bg-secondary text-light w-100"
          v-if="
            day.disable_count && day.disable_count !== 0 && similarUnit !== 0
          "
          style="font-size: 0.65rem"
        >
          {{
            `
        ${day.disable_count}
        از
        ${similarUnit}
        پر`
          }}
        </div>

        <div
          class="bg-success w-100"
          v-if="day.books_count && day.books_count !== 0 && similarUnit !== 0"
        >
          <small style="font-size: 0.65rem">
            {{
              `
        ${day.books_count}
        از
        ${similarUnit}
        رزرو`
            }}
          </small>
        </div>
        <template v-if="!dayIsPeak">
          <div
            style="text-decoration: line-through; font-size: 0.65rem"
            class="w-100 text-muted"
            v-if="day.price && day.discount"
          >
            {{ numberFormat(day.price) }}


          </div>
          <div
            style="font-size: 0.65rem"
            class="w-100"
            v-if="day.price && day.discount"
          >
            {{ day.price * ((100 - day.discount) / 100) }}
            <br/>
            <span  v-if="day.price && day.discount" style="color: red">
              {{day.discount}} درصد
            </span>
            <br/>
            <span  v-if="day.extra_guest_price" style="color: #014929;padding: 2px;background: #00cfe8">
              {{numberFormat(day.extra_guest_price)}} تومان
            </span>
          </div>
          <div
            style="font-size: 0.72rem"
            class="w-100"
            v-if="day.price && (!day.discount || day.discount == 0)"
          >
            {{ numberFormat(day.price) }}
            <br/>
            <span  v-if="day.extra_guest_price" style="color: #014929;padding: 2px;background: #00cfe8">
              {{numberFormat(day.extra_guest_price)}} تومان
            </span>
          </div>
        </template>
        <template v-if="dayIsPeak">
          <div
            style="text-decoration: line-through; font-size: 0.72rem"
            class="w-100 text-muted"
            v-if="day.price && day.discount"
          >
<!--            {{ peakPrice }}-->

            {{ numberFormat(day.price) }}
            <br/>
            <span  v-if="day.extra_guest_price" style="color: #014929;padding: 2px;background: #00cfe8">
              {{numberFormat(day.extra_guest_price)}} تومان
            </span>
          </div>
          <div
            style="font-size: 0.72rem"
            class="w-100"
            v-if="day.price && day.discount"
          >
<!--            {{ peakPrice * ((100 - day.discount) / 100) }} -->
            {{ day.price * ((100 - day.discount) / 100) }}
            <br/>
            <span  v-if="day.price && day.discount">
              {{day.discount}} درصد
            </span>
            <br/>
            <span  v-if="day.extra_guest_price" style="color: #014929;padding: 2px;background: #00cfe8">
              {{numberFormat(day.extra_guest_price)}} تومان
            </span>
            <br/>
            <span  v-if="day.extra_guest_price" style="color: #014929;padding: 2px;background: #00cfe8">
              {{numberFormat(day.extra_guest_price)}} تومان
            </span>
          </div>
          <div
            style="font-size: 0.72rem;"
            class="w-100"
            v-if="day.price && (!day.discount || day.discount == 0)"
          >
            <span v-if="day.is_custom_price">
                  {{ numberFormat(day.price) }}

            </span>
            <span v-else>
               {{ numberFormat(peakPrice) }}
            </span>
            <br/>
            <span  v-if="day.extra_guest_price" style="color: #014929;padding: 2px;background: #00cfe8">
              {{numberFormat(day.extra_guest_price)}} تومان
            </span>

          </div>
        </template>

        <div class="text-center w-100 d-flex justify-content-center">
          <div
            style="width: 10px; height: 10px; font-size: 0.72rem"
            class="text-danger rounded bg-danger"
            v-if="day.price && day.discount"
          ></div>
        </div>
        <div
          v-if="day.minNight"
          style="font-size: 0.72rem"
          class="w-100 text-light text-smaller bg-warning"
        >
          {{
            `<
            ${day.minNight}
           شب`
          }}
        </div>
      </div>
      <BIconLightningFill
        style="top: 5px; right: 5px; color: orange"
        class="position-absolute"
        v-if="day.instant && day.instant == 'instant'"
      ></BIconLightningFill>
    </li>

    <b-tooltip
      v-if="isLessThanToday"
      triggers="hover"
      :target="day.date"
      noninteractive
      >امکان انتخاب این شب وجود ندارد</b-tooltip
    >
  </div>
</template>

<script>
import { BTooltip, BIconLightningFill } from "bootstrap-vue";
import moment from 'moment-jalaali';
export default {
  name: "CalendarMonthDayItem",
  components: {
    BTooltip,
    BIconLightningFill,
  },
  props: {
    similarUnit: {
      type: Number,
      default: 0,
    },
    day: {
      type: Object,
      required: true,
    },

    isCurrentMonth: {
      type: Boolean,
      default: false,
    },

    isToday: {
      type: Boolean,
      default: false,
    },
    isLessThanToday: {
      type: Boolean,
      default: false,
    },
    isInRange: {
      type: Boolean,
      default: false,
    },
    isHoveredInRange: {
      type: Boolean,
      default: false,
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    peakDates: {
      type: Array,
      default: () => [],
    },
    peakPrice: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    label2() {
      return this.$moment(this.day.date).format("D");
    },
    label() {
      // Convert the Persian (Jalali) date to the Gregorian date
      const gregorianDate = moment(this.day.date, 'jYYYY-jMM-jDD').format('D');
      return gregorianDate;
    },
    dayIsPeak() {
      console.log("aaaaa");
      var x = false;
      for (var i = 0; i < this.peakDates.length; i++) {
        if (
          this.$moment(new Date(this.peakDates[i]))
            .locale("fa")
            .format("YYYY-MM-DD") == this.day.date
        ) {
          x = true;
          break;
        } else {
          x = false;
        }
      }
      return x;
    },
  },
  methods: {
    convertToGregorian(date) {
      return this.$moment(date, 'jYYYY-jMM-jDD').format('YYYY-MM-DD');
    },
    clicked() {
      alert("234");
    },
    numberFormat(number) {
      return  new Intl.NumberFormat().format(number)
    },
  },
};
</script>

<style scoped>
.calendar-day--in-range {
  background-color: #968dfd !important;
}
.calendar-day--in-hovered-range {
  background-color: #968dfd !important;
}
.calendar-day--is-selected {
  background-color: #7367f0 !important;
}
.calendar-day--is-selected > div {
  color: #fff;
}
.calendar-day--in-hovered-range > div {
  color: #fff;
}
.calendar-day--in-range > div {
  color: #fff;
}
.calender-day--is-peak > div {
  color: red !important;
}
.calendar-day {
  position: relative;
  min-height: 100px;
  font-size: 16px;
  background-color: #fff;
  color: var(--grey-800);
  padding: 5px;
  list-style-type: none !important;
  justify-content: center !important;
  align-items: center;
  display: flex;
  text-align: center !important;
}

.calendar-day > div {
  text-align: center;
  width: 100%;
  height: var(--day-label-size);
}

.calendar-day--not-current {
  background-color: var(--grey-100);
  color: var(--grey-300);
  list-style-type: none !important;
}

.calendar-day--today {
  padding-top: 4px;
  list-style-type: none !important;
  border: 1px solid rgb(255, 151, 91);
  border-radius: 10px;
}
.calendar-day--less-than-today {
  background-color: var(--grey-100) !important;
}
.calendar-day--less-than-today > div {
  color: rgb(211, 211, 211) !important;
}
.calendar-day--today > div {
  color: rgb(0, 0, 0);
  /* border-radius: 10px; */

  background-color: var(--grey-800);
}
.calendar-day--is-custom-price {
  border: 1px solid blue;
}
</style>
