<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />
      </b-col>
      <div v-if="residence" class="text-left w-100 mt-1 ml-1">
        {{ `${residence.name} | ${residence.city.name}` }}
        <div>
          <span class="mr-2">
            آخرین روز تقویم :
            {{
              new Date(
                this.calender.data[this.calender.data.length - 1].date
              ).toLocaleDateString("fa-IR", {
                timeZone: "Iran",
              })
            }}
          </span>
          <b-button @click="saveCourierCalendars(residence.id)">
            <div v-if="submitUpdateCalenderLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize"> تمدید دو ماهه تقویم </span>
          </b-button>
        </div>
      </div>
      <!-- Content Right -->
      <b-col
        v-if="this.calender"
        class="content-header-right text-md-right"
        md="12"
        cols="12"
      >

        <CalendarMonth
          ref="calender"
          @showSidebar="showFormModal"
          :residenceAvailabelDates="calender"
          :similar-unit="Number(this.residence.similar_unit)"
          @closeSideBar="closeSideBar"
          :peak-dates="peakDates"
          :peak-pric="Number(this.residence.prices.peack_days)"
        />
      </b-col>
    </b-row>
    <b-sidebar
      width="500px"
      v-model="formModal"
      right
      backdrop
      shadow
      bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="formModal = false"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">تغییرات را انتخاب و اعمال کنید.</h3>
          </div>
        </div>
      </template>
      <b-container class="">
        <b-form-group class="mt-1">
          <label for="fullOrEmptyOptions" class="text-capitalize"
            >پر / خالی</label
          >
          <v-select
            id="fullOrEmptyOptions"
            :components="{ OpenIndicator }"
            class="bg-white"
            :clearable="false"
            v-model="calenderInput.fullOrEmptyOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :reduce="(option) => option.name"
            :options="fullOrEmptyOptions"
          ></v-select>
        </b-form-group>

        <b-form-group class="mt-1">
          <label for="fullOrEmptyOptions" class="text-capitalize"
            >خالی کردن سبد خرید (در صورتی که رزرو شده و میخواهید آن را خالی کنید
            )</label
          >
          <v-select
            id="fullOrEmptyReserveOptions"
            :components="{ OpenIndicator }"
            class="bg-white"
            :clearable="false"
            v-model="calenderInput.fullOrEmptyReserveOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :reduce="(option) => option.name"
            :options="fullOrEmptyReserveOptions"
          ></v-select>
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="special_day_price" class="text-capitalize"
            >نرخ گذاری روزهای خاص:
          </label>
          <b-form-input
            id="special_day_price"
            v-model="calenderInput.price"
            type="text"
          />
          <div class="mt-1">{{ numberFormat(calenderInput.price) }}</div>
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="extra_guest_price" class="text-capitalize"
            >هزینه نفرات اضافه:
          </label>
          <b-form-input
            id="extra_guest_price"
            v-model="calenderInput.extra_guest_price"
            type="text"
          />
          <div class="mt-1">
            {{ numberFormat(calenderInput.extra_guest_price) }}
          </div>
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="instant" class="text-capitalize">آنی/ غیرآنی</label>
          <v-select
            id="instant"
            :components="{ OpenIndicator }"
            class="bg-white"
            :clearable="false"
            v-model="calenderInput.instant"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :reduce="(option) => option.name"
            :options="instantOptions"
          ></v-select>
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="discount" class="text-capitalize"> %تخفیف: </label>
          <b-form-input
            id="discount"
            v-model="calenderInput.discount"
            type="text"
          />
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="min-night" class="text-capitalize mr-1">
            حداقل مدت اقامت :
          </label>

          <b-form-spinbutton
            inline
            id="min-night"
            min="1"
            v-model="calenderInput.minNight"
          ></b-form-spinbutton>
        </b-form-group>
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
            class="text-capitalize mr-2"
            @click="
              () => {
                (formModal = false), ($refs.calender.showTop = true);
              }
            "
            variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="submitForm()">
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BIconCalendar,
  BAlert,
  BFormSpinbutton,
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";
import ResidenceWizard from "@/components/ResidenceWizard.vue";
import { mapMutations, mapState, mapActions } from "vuex";
import { forEach } from "postcss-rtl/lib/affected-props";
import CalendarMonth from "@/components/calender/CalendarMonth";

export default {
  components: {
    ResidenceWizard,
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BIconCalendar,
    CalendarMonth,
    BAlert,
    BFormSpinbutton,
  },
  data() {
    return {
      peakDates: [],
      calender: null,
      formModal: false,
      fullOrEmptyOptions: [
        { name: "none", label: "بدون تغییر" },
        { name: "empty", label: "همه اقامتگاه ها خالی هستند" },
      ],
      fullOrEmptyReserveOptions: [
        { name: "none", label: "بدون تغییر" },
        { name: "empty", label: "همه اقامتگاه ها خالی هستند" },
      ],
      instantOptions: [
        { name: "instant", label: "آنی" },
        { name: "non_instantaneous", label: "غیرآنی" },
      ],
      calenderInput: {
        fullOrEmptyOptions: "none",
        fullOrEmptyReserveOptions: "none",
        instant: "non_instantaneous",
        minNight: null,
        price: "",
        extra_guest_price: "",
        discount: "",
        minNight: "",
      },
      submitLoading: false,
      submitUpdateCalenderLoading: false,
      OpenIndicator: {
        render: (createElement) =>
          createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      date: {
        books_count: 1,
        date: "2022-11-20",
        disable_count: 0,
        is_custom_price: 1,
        price: 700000,
      },
      residence: null,
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);

    this.getResidenceCalendar();
    this.getResidence();
    this.getPeakCalender();
  },
  watch: {},
  computed: {},

  methods: {
    numberFormat(number) {
      return new Intl.NumberFormat().format(number);
    },

    async getPeakCalender() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      const requestData = {
        method: "get",
        url: "/admin/courier_calendars",
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data.data[0].data;
          this.peakDates = data;
          console.log("this.peakDates", this.peakDates);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeSideBar() {
      this.formModal = false;
      (this.calenderInput = {
        fullOrEmptyOptions: "none",
        fullOrEmptyReserveOptions: "none",
        instant: "non_instantaneous",
        minNight: null,
        price: "",
        extra_guest_price: "",
        discount: "",
        minNight: "",
      }),
        this.getResidenceCalendar();
    },
    showFormModal() {
      this.formModal = true;
      this.$refs.calender.showTop = false;
    },

    async getResidenceCalendar() {
      if (typeof this.provincesCancelToken != typeof undefined) {
        this.provincesCancelToken.cancel(
          "Operation canceled due to new request."
        );
      }
      //Save the cancel token for the current request
      this.provincesCancelToken = this.$http.CancelToken.source();

      let data = {
        paginate: false,
        //  this.searchInput,
        // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: `/admin/residence/calendars/${this.$route.params.id}`,
        // cancelToken: this.cancelToken.token,
      };
      await this.$http(requestData)
        .then((response) => {
          let data = response.data;
          console.log("tagh:", data);
          this.calender = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getResidence() {
      if (typeof this.provincesCancelToken != typeof undefined) {
        this.provincesCancelToken.cancel(
          "Operation canceled due to new request."
        );
      }
      //Save the cancel token for the current request
      this.provincesCancelToken = this.$http.CancelToken.source();

      let data = {
        paginate: false,
        //  this.searchInput,
        // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: `/admin/residences/${this.$route.params.id}`,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;

          this.residence = data.data;
          console.log(
            "this.residence.similar_unit:",
            this.residence.similar_unit
          );
          this.createFullOrNotOptions(this.residence.similar_unit);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createFullOrNotOptions(similar_unit) {
      var option = [
        { name: "none", label: "بدون تغییر" },
        { name: "0", label: "همه اقامتگاه ها خالی هستند" },
      ];
      var index = 1;
      while (index <= Number(similar_unit)) {
        option.push({ name: index, label: `${index} اقامتگاه پر است` });
        index = index + 1;
      }
      this.fullOrEmptyOptions = option;
      this.fullOrEmptyReserveOptions = option;
    },
    submitForm() {
      var dataToedit = {};
      if (this.calenderInput.fullOrEmptyOptions == "none") {
      } else {
        dataToedit["disable_count"] = Number(
          this.calenderInput.fullOrEmptyOptions
        );
      }
      if (this.calenderInput.fullOrEmptyReserveOptions == "none") {
      } else {
        dataToedit["books_count"] = Number(
          this.calenderInput.fullOrEmptyReserveOptions
        );
      }
      if (this.calenderInput.price) {
        dataToedit["price"] = Number(this.calenderInput.price);
        dataToedit["is_custom_price"] = 1;
      }
      if (this.calenderInput.extra_guest_price) {
        dataToedit["extra_guest_price"] = Number(
          this.calenderInput.extra_guest_price
        );
      }
      if (this.calenderInput.instant) {
        dataToedit["instant"] = this.calenderInput.instant;
      }
      if (this.calenderInput.discount) {
        dataToedit["discount"] = Number(this.calenderInput.discount);
      }
      if (this.calenderInput.minNight) {
        dataToedit["minNight"] = this.calenderInput.minNight;
      }

      this.$refs.calender.dataToedit = dataToedit;
      console.log("dataToedit:", dataToedit);
      this.$refs.calender.residenceId = this.residence.id;
      this.$refs.calender.setData("residence", this.residence.id);
    },

    monthsBetweenDates(startDate, endDate, id) {
      const now = startDate,
        dates = [];
      // console.log("startDate:", startDate, "endDate:", endDate);
      while (now.isSameOrBefore(endDate)) {
        // console.log("now", now, now.isoWeekday());
        if (now.isoWeekday() === 3) {
          // console.log(now.format("dddd"));
          // console.log('now',now,now.weekday());
          dates.push({
            residence_id: id,
            date: now.locale("en").format("YYYY-MM-DD"),
            price: this.residence.prices.weekend_days,
            instant: null,
            discount: null,
            minNight: null,
            isWeakened: 1,
            books_count: 0,
            disable_count: 0,
            is_custom_price: 0,
            extra_guest_price: null,
          });
        } else if (now.isoWeekday() === 4) {
          // console.log(now.format("dddd"));
          // console.log('now',now,now.weekday());
          dates.push({
            residence_id: id,
            date: now.locale("en").format("YYYY-MM-DD"),
            price: this.residence.prices.weekend_days,
            instant: null,
            discount: null,
            minNight: null,
            isWeakened: 1,
            books_count: 0,
            disable_count: 0,
            is_custom_price: 0,
            extra_guest_price: null,
          });
        } else {
          dates.push({
            residence_id: id,
            date: now.locale("en").format("YYYY-MM-DD"),
            price: this.residence.prices.normal_dayes,
            instant: null,
            discount: null,
            minNight: null,
            isWeakened: 0,
            books_count: 0,
            disable_count: 0,
            is_custom_price: 0,
            extra_guest_price: null,
          });
        }

        now.add(1, "days");
      }
      return dates;
    },
    getDatesFromStartTo6Month(id) {
      // console.log(this.$moment)
      var lastIndex = this.calender.data.length - 1;
      var lastDateOfcalender = this.calender.data[lastIndex].date;
      const endofMonth1 = this.$moment(new Date(lastDateOfcalender));
      const toDate1 = this.$moment(endofMonth).endOf("month");
      // console.log('endofMonth1',endofMonth1,'toDate1:', toDate1);
      // console.log("monthsBetweenDates",this.monthsBetweenDates(endofMonth1,toDate1))
      // console.log("date",this.$options.filters.moment(this.date,"jD jMMMM,jYYYY HH:mm").getDay())
      // console.log(this.$moment(this.date).endOf('month').format('YYYY/MM/D'))
      const endofMonth = this.$moment(new Date(lastDateOfcalender)).endOf(
        "month"
      );
      const toDate = this.$moment(endofMonth).add(2, "months").endOf("month");

      // console.log("toDate",toDate.format('YYYY/MM/D'))
      // console.log("monthsBetweenDates",this.monthsBetweenDates(endofMonth,toDate))
      var x = this.monthsBetweenDates(endofMonth1, toDate1, id);

      // x.splice(-1);
      var y = this.monthsBetweenDates(endofMonth, toDate, id);
      y.shift();
      // this.date.moment("jD jMMMM,jYYYY HH:mm").getDay()
      this.Dates = [...x, ...y];

      // console.log(this.Dates);
      return this.Dates;
    },
    updateCalender(id) {
      var addedCalenderDates = this.getDatesFromStartTo6Month(id);
      if (
        this.calender.data[this.calender.data.length - 1].date ==
        addedCalenderDates[0].date
      ) {
        addedCalenderDates.shift();
      }
      // a.push(...b)
      var updatedCalender = [...this.calender.data, ...addedCalenderDates];
      // console.log('updatedCalender:',this.calender.data,addedCalenderDates)
      return updatedCalender;
    },

    async saveCourierCalendars(id) {
      // console.log({ data: this.getDatesFromStartTo6Month() });
      this.submitUpdateCalenderLoading = true;
      const requestData = {
        method: "post",
        url: `/admin/residence/calendars/${id}`,
        data: { calendars: await this.updateCalender(id) },
      };

      await this.$http(requestData)
        .then(async (response) => {
          // console.log(response)
          let message = "شش ماه به روزهای تقویم اضافه شد";
          this.$bvToast.toast(message, {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
          this.getResidenceCalendar();
          this.submitUpdateCalenderLoading = false;
        })
        .catch((err) => {
          let message = "خطا در بروزرسانی تفویم اقامتگاه";
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;

          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
          });
          this.submitUpdateCalenderLoading = false;

          console.log(err);
        });
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: "Form Submitted",
      //     icon: "EditIcon",
      //     variant: "success",
      //   },
      // });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
