<template>
  <div v-if="residenceAvailabelDates" class="calendar-month">
    <b-form-group v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        id="btn-radios-2"
        v-model="mode"
        :options="options"
        :aria-describedby="ariaDescribedby"
        button-variant="outline-primary"
        size="md"
        name="radio-btn-outline"
        buttons
        @change="changeMode"
      ></b-form-radio-group>
    </b-form-group>
    <div class="calendar-month-header">
      <CalendarDateIndicator
        :selected-date="selectedDate"
        class="calendar-month-header-selected-month"
      />

      <CalendarDateSelector
        :current-date="today"
        :selected-date="selectedDate"
        @dateSelected="selectDate"
      />
    </div>

    <CalendarWeekdays />

    <ol v-if="residenceAvailabelDates && peakDates" class="days-grid">
      <CalendarMonthDayItem
        v-for="day in days"
        :key="day.date"
        :day="day"
        :is-today="day.date === today"
        :is-less-than-today="day.date < today"
        :is-in-range="isInRange(day)"
        :is-hovered-in-range="isHoveredInRange(day)"
        @selectDate="selectDates"
        @setHoverDate="setHoverDate"
        :is-select="dateIsSelected(day)"
        :similar-unit="similarUnit"
        :peak-dates="peakDates"
        :peak-price="peakPrice"
      />
    </ol>
    <b-alert
      v-model="showTop"
      class="position-fixed fixed-bottom m-0 rounded-0 text-center pt-1"
      style="z-index: 2000; height: 80px"
      variant="dark"
    >
      <b-button @click="cancelEdit" variant="secondary">لغو</b-button>
      <b-button @click="showFormModal" variant="primary" class="ml-2"
        >ویرایش ({{ daysCount }})روز</b-button
      >
    </b-alert>
  </div>
</template>

<script>
// import this.$moment from "this.$moment";
// import weekday from "this.$moment/plugin/weekday";
// import weekOfYear from "this.$moment/plugin/weekOfYear";
import CalendarMonthDayItem from "./CalendarMonthDayItem";
import CalendarDateIndicator from "./CalendarDateIndicator";
import CalendarDateSelector from "./CalendarDateSelector";
import CalendarWeekdays from "./CalendarWeekdays";
import {
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BAlert,
  BButton,
} from "bootstrap-vue";
// this.$moment.extend(weekday);
// this.$moment.extend(weekOfYear);

export default {
  name: "CalendarMonth",
  props: ["residenceAvailabelDates","similarUnit","peakDates","peakPric"],
  components: {
    CalendarMonthDayItem,
    CalendarDateIndicator,
    CalendarDateSelector,
    CalendarWeekdays,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BAlert,
    BButton,
  },

  data() {
    return {
      selectedDate: this.$moment(),
      selectedDate1: "",
      selectedDate2: "",
      selectedDates: [],
      isSelectingDate1: true,
      hoverDate: "",
      mode: "range",
      isSelectingDate1: true,
      options: [
        { text: "بازه ای", value: "range" },
        { text: "روزانه", value: "single" },
      ],
      showTop: false,
      daysCount: 0,
      dataToedit: {},
      residenceId:null
    };
  },

  computed: {
    peakPrice(){
        return this.peakPric
    },
    isSingleMode() {
      return this.mode === "single";
    },
    days() {
      return [
        ...this.previousMonthDays,
        ...this.currentMonthDays,
        ...this.nextMonthDays,
      ];
    },

    today() {
      return this.$moment().format("YYYY-MM-DD");
    },

    month() {
      return Number(this.selectedDate.format("M"));
    },

    year() {
      return Number(this.selectedDate.format("YYYY"));
    },

    numberOfDaysInMonth() {
      return this.$moment(this.selectedDate).daysInMonth();
    },

    currentMonthDays() {
      return [...Array(this.numberOfDaysInMonth)].map((day, index) => {
        return {
          ...this.checkDate(this.$moment(`${this.year}-${this.month}-${index + 1}`).format(
            "YYYY-MM-DD"))
        };
      });
    },

    previousMonthDays() {
      const firstDayOfTheMonthWeekday = this.getWeekday(
        this.currentMonthDays[0].date
      );
      const previousMonth = this.$moment(
        `${this.year}-${this.month}-01`
      ).subtract(1, "month");

      // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
        ? firstDayOfTheMonthWeekday
        : 7;

      const previousMonthLastMondayDayOfMonth = this.$moment(
        this.currentMonthDays[0].date
      )
        .subtract(visibleNumberOfDaysFromPreviousMonth, "day")
        .date();

      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map(
        (day, index) => {
          return {
            date: this.$moment(
              `${previousMonth.year()}-${previousMonth.month() + 1}-${
                previousMonthLastMondayDayOfMonth + index
              }`
            ).format("YYYY-MM-DD"),
            isCurrentMonth: false,
          };
        }
      );
    },

    nextMonthDays() {
      const lastDayOfTheMonthWeekday = this.getWeekday(
        `${this.year}-${this.month}-${this.currentMonthDays.length}`
      );

      const nextMonth = this.$moment(`${this.year}-${this.month}-01`).add(
        1,
        "month"
      );

      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
        ? 6 - lastDayOfTheMonthWeekday
        : lastDayOfTheMonthWeekday;

      return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {

        return {
          date: this.$moment(
            `${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`
          ).format("YYYY-MM-DD"),
          isCurrentMonth: false,
        };
      });
    },
    allDatesSelected() {
      return (
        this.selectedDate1 &&
        this.selectedDate1 !== "" &&
        this.selectedDate2 &&
        this.selectedDate2 !== ""
      );
    },
  },
  watch: {
    selectedDates(newVal, oldVal) {
      if (newVal.length > 0) {
        this.showEditBtn(newVal.length);
      } else {
        this.showEditBtn(0);
      }
    },

    // mode(newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     console.log(newVal)
    //     this.selectedDate1 = "";
    //     this.selectedDate2 = "";
    //   }
    // },
  },
  methods: {
    checkDate(xdate){
      var x={}
      for(var i=0;i<this.residenceAvailabelDates.data.length;i++){
        if(
          this.$moment(new Date(this.residenceAvailabelDates.data[i].date)).locale('fa').format('YYYY-MM-DD')
          ==
          xdate

          ){
            x={...this.residenceAvailabelDates.data[i],date:xdate,isCurrentMonth: true}
            break
        }else{
          x={date:xdate,isCurrentMonth: true}

        }

      }

return {...x}
    },

    showFormModal() {
      this.$emit("showSidebar");
    },
    showEditBtn(daysCountToEdit) {

      this.daysCount = daysCountToEdit;
      if (daysCountToEdit > 0) {
        this.showTop = true;
      } else {
        this.showTop = false;
      }
    },
    cancelEdit() {
      this.selectedDate1 = "";
      this.selectedDate2 = "";
      this.selectedDates = [];
    },
    changeMode(value) {
      this.selectedDate1 = "";
      this.selectedDate2 = "";
      this.selectedDates = [];
      // console.log(value);
    },
    // isBefore(date1,date2){
    //   console.log("date1,date2",date1,date2)
    // },
    dateIsSelected(day) {
      return (
        day &&
        (this.selectedDate1.date === day.date ||
          this.selectedDate2.date === day.date)
      );
    },
    setHoverDate(date) {
      // console.log(date)
      this.hoverDate = date;
    },
    createNewArray() {
      var x = [];
      this.selectedDates.forEach((element) => {
        x.push({ date: element.date, isCurrentMonth: element.isCurrentMonth });
      });
      return x;
    },

    isInselectedDate(date) {
      // console.log("isInselectedDate",date)
      // console.log(
      //   "isInselectedDate",
      //   this.createNewArray(this.selectedDates),
      //   date
      // );
      //  this.selectedDates.forEach((element) => {
      //    if(element.date==date.date){
      //     console.log('true',element.date)
      //    return true }
      //    else {
      //     console.log('false',element.date)
      //     return false}
      //   });
      var x = false;

      //   // return x;
      for (var i = 0; i < this.selectedDates.length; i++) {
        if (date.date == this.selectedDates[i].date) {
          x = true;
          break;
        } else {
          x = false;
        }
      }
      // console.log(x);
      return x;
    },
    isInRange(date) {
      // console.log("raaange", this.mode);
      if (this.mode == "single") {
        if (this.isInselectedDate(date)) {
          return true;
        } else {
          return false;
        }
        // return this.isInselectedDate(date);
      } else {
        if (!this.allDatesSelected || this.isSingleMode) {
          return false;
        }

        return (
          (this.isAfter(date, this.selectedDate1) &&
            this.isBefore(date, this.selectedDate2)) ||
          (this.isAfter(date, this.selectedDate2) &&
            this.isBefore(date, this.selectedDate1))
          //  &&
          //   this.isBefore(date, this.selectedDate2)) ||
          // (this.isAfter(date, this.selectedDate1) &&
          //   this.isBefore(date, this.hoverDate)
          //  && !this.allDatesSelected
        );
      }
    },
    isHoveredInRange(date) {
      if (this.isSingleMode || (this.selectedDate1 && this.selectedDate2)) {
        return false;
      }

      return (
        (this.isAfter(date, this.selectedDate1) &&
          this.isBefore(date, this.hoverDate)) ||
        (this.isAfter(date, this.hoverDate) &&
          this.isBefore(date, this.selectedDate1))
          ||
          this.isSame(date, this.hoverDate)
      );
    },
    isSame(date, targetDate){
if (!targetDate || !date) {
        return false;
      }
      if (date.date== targetDate.date && this.selectedDate1) {
        // console.log("isSame");
        return true;
      } else {
        // console.log("isNoteSame",date.date,targetDate.date);
        return false;
      }
    },
    isBefore(date, targetDate) {
      // console.log("isBefore");
      if (!targetDate || !date) {
        return false;
      }
      // console.log("date, targetDate",date, targetDate);
      if (this.$moment(date.date) < this.$moment(targetDate.date)) {
        // console.log("isBefore");
        return true;
      } else {
        // console.log("isNoteBefore");
        return false;
      }
    },
    isAfter(date, targetDate) {
      // console.log("isAfter");
      if (!targetDate || !date) {
        return false;
      }
      // console.log("date, targetDate",date, targetDate);
      if (this.$moment(date.date) > this.$moment(targetDate.date)) {
        // console.log("isAfter");
        return true;
      } else {
        // console.log("isNoteAfter");
        return false;
      }
    },
    selectDates(date) {
      // console.log("انتخاب شد",date)
      // if (
      //   this.isBeforeMinDate(date) ||
      //   this.isAfterEndDate(date) ||
      //   this.isDateDisabled(date)
      // ) {
      //   return;
      // }
      if (
        this.$moment(date.date) < this.$moment() &&
        date.date !== this.$moment().format("YYYY-MM-DD")
      ) {
        return;
      }
      if (this.mode == "single") {
        if (this.isInselectedDate(date)) {
          var index = null;
          for (var i = 0; i < this.selectedDates.length; i++) {
            if (date.date == this.selectedDates[i].date) {
              index = i;
              break;
            }
          }
          // console.log(this.selectedDates);
          this.selectedDates.splice(index, 1);
          return;
        } else {
          this.selectedDates.push(date);
        }
        return;
      }
      // this.selectedDate1 = date;
      if (this.isSelectingDate1) {
        // if (this.isBefore(this.selectedDate2, date)) {
        this.selectedDate2 = "";
        // }
        this.selectedDate1 = date;
        this.isSelectingDate1 = false;
      } else {
        this.selectedDate2 = date;
        this.isSelectingDate1 = true;

        // if (this.isAfter(this.selectedDate1, date)) {
        //   this.selectedDate1 = "";
        // } else {
        // if user has selected both dates, focus the apply button for accessibility
        // alert(`${this.selectedDate1.date}-${this.selectedDate2.date}`);
        var selectedDate1 = "";
        var selectedDate2 = "";
        if (
          this.$moment(this.selectedDate1.date) <
          this.$moment(this.selectedDate2.date)
        ) {
          selectedDate1 = this.selectedDate1.date;
          selectedDate2 = this.selectedDate2.date;
        } else {
          selectedDate1 = this.selectedDate2.date;
          selectedDate2 = this.selectedDate1.date;
        }
        var count =
          this.$moment(selectedDate2).diff(
            this.$moment(selectedDate1),
            "days"
          ) + 1;
        this.showEditBtn(count);
        // }

        // if (this.allDatesSelected && this.closeAfterSelect) {
        //   this.closeDatepicker();
        // }
      }
    },
    getWeekday(date) {
      // console.log(this.$moment().weekday());
      // console.log(this.$moment(date), this.$moment(date).weekday());
      return this.$moment(date).weekday();
    },

    selectDate(newSelectedDate) {
      this.selectedDate = newSelectedDate;
    },
    datesFromStartToSelectedDate1(start, SelectedDate1) {
      var datesFromStartToSelectedDate1 = [];
      this.residenceAvailabelDates.data.forEach((item, index) => {
        // console.log(
        //   this.$moment(new Date(item.date)),
        //   this.$moment(SelectedDate1)
        // );
        if (
          this.$moment(new Date(item.date)).isBefore(
            this.$moment(SelectedDate1)
          )
        ) {
          // console.log("برابره");
          datesFromStartToSelectedDate1.push(JSON.parse(JSON.stringify(item)));
        } else {
          return;
        }
      });

      return datesFromStartToSelectedDate1;
    },
    datesFromSelectedDate1ToSelectedDate2(SelectedDate1, SelectedDate2) {
      var datesFromSelectedDate1ToSelectedDate2 = [];
      var index2 = 0;
      console.log('this.residenceAvailabelDates.data',this.residenceAvailabelDates.data)
      this.residenceAvailabelDates.data.forEach((item, index) => {
        if (
          (this.$moment(new Date(item.date)) >= this.$moment(SelectedDate1) &&
          this.$moment(new Date(item.date)) <= this.$moment(SelectedDate2)) ||
          item.date==this.$moment(SelectedDate2).locale('en').format('YYYY-MM-DD')
        ) {
          console.log('item',item)
          console.log('SelectedDate1',SelectedDate1)
          console.log('SelectedDate2',SelectedDate2)
          datesFromSelectedDate1ToSelectedDate2.push({
            ...item, ...this.dataToedit
          });
          // Object.keys(datesFromSelectedDate1ToSelectedDate2).forEach(key => {
          //   datesFromSelectedDate1ToSelectedDate2[index2][key]=datesFromSelectedDate1ToSelectedDate2[index2][key]
          // });
          // console.log("this.dataToedit", this.dataToedit);
          // Object.keys(this.dataToedit).forEach((key) => {
            // console.log("datesFromSelectedDate1ToSelectedDate2",datesFromSelectedDate1ToSelectedDate2)
            //               console.log("[index]",index)
          // {...obj1, ...obj2, ...obj3}
          //   datesFromSelectedDate1ToSelectedDate2[index2][key] =
              // this.dataToedit[key];
          // });
          // datesFromSelectedDate1ToSelectedDate2[index2]["date"] = item.date;
          index2 = index2 + 1;
        } else {
          return;
        }
      });
      console.log('datesFromSelectedDate1ToSelectedDate2',datesFromSelectedDate1ToSelectedDate2)
      return datesFromSelectedDate1ToSelectedDate2;
    },
    datesFromSelectedDate2ToEnd(SelectedDate2, End) {
      var datesFromSelectedDate2ToEnd = [];
      var index2=0
      this.residenceAvailabelDates.data.forEach((item, index) => {
        if (
          this.$moment(new Date(item.date)) > this.$moment(SelectedDate2).locale('en') &&
          this.$moment(new Date(item.date)) <= this.$moment(End).locale('en') &&
          this.$moment(new Date(item.date)).locale('en').format('YYYY-MM-DD') !== this.$moment(SelectedDate2).locale('en').format('YYYY-MM-DD')
        ) {
          console.log('item.date:',this.$moment(new Date(item.date)).locale('en').format('YYYY-MM-DD'),'SelectedDate2:',this.$moment(SelectedDate2).locale('en').format('YYYY-MM-DD'))
          datesFromSelectedDate2ToEnd.push({})
          Object.keys(item).forEach(key => {
            datesFromSelectedDate2ToEnd[index2][key]=item[key]
          });
          // datesFromSelectedDate2ToEnd.push(JSON.parse(JSON.stringify(item)));
          index2=index2+1
        } else {
          return;
        }
      });
      console.log('datesFromSelectedDate2ToEnd:',datesFromSelectedDate2ToEnd)
      return datesFromSelectedDate2ToEnd;
    },
    multipleDays(){
 var multipleDays = [];
      var index2 = 0;
      this.residenceAvailabelDates.data.forEach((item, index) => {
        if (

          this.isInselectedDate({date:this.$moment(new Date(item.date)).locale('fa').format('YYYY-MM-DD')})
        ) {
          multipleDays.push({
            ...item, ...this.dataToedit
          });
          // Object.keys(datesFromSelectedDate1ToSelectedDate2).forEach(key => {
          //   datesFromSelectedDate1ToSelectedDate2[index2][key]=datesFromSelectedDate1ToSelectedDate2[index2][key]
          // });
          // console.log("this.dataToedit", this.dataToedit);
          // Object.keys(this.dataToedit).forEach((key) => {
            // console.log("datesFromSelectedDate1ToSelectedDate2",datesFromSelectedDate1ToSelectedDate2)
            //               console.log("[index]",index)
          // {...obj1, ...obj2, ...obj3}
          //   datesFromSelectedDate1ToSelectedDate2[index2][key] =
              // this.dataToedit[key];
          // });
          // datesFromSelectedDate1ToSelectedDate2[index2]["date"] = item.date;
          index2 = index2 + 1;
        } else {
          multipleDays.push({
            ...item
          });
          return;
        }})
        return multipleDays
    },
    setData(url,id) {
      if(this.mode=="range"){
      var selectedDate1 = "";
      var selectedDate2 = "";
      if (
        this.$moment(this.selectedDate1.date) <
        this.$moment(this.selectedDate2.date)
      ) {
        selectedDate1 = this.selectedDate1.date;
        selectedDate2 = this.selectedDate2.date;
      } else {
        selectedDate1 = this.selectedDate2.date;
        selectedDate2 = this.selectedDate1.date;
      }

      var length = this.residenceAvailabelDates.data.length;
      this.updateCalender([
        ...this.datesFromStartToSelectedDate1(
          this.residenceAvailabelDates.data[0].date,
          selectedDate1
        ),
        ...this.datesFromSelectedDate1ToSelectedDate2(
          selectedDate1,
          selectedDate2
        ),
        ...this.datesFromSelectedDate2ToEnd(
          selectedDate2,
          this.residenceAvailabelDates.data[length - 1].date
        ),
      ],id,url)}else{
        this.updateCalender(this.multipleDays(),id,url)
      }

    },
    updateCalender(dates,id,url){

      console.log('dates::',dates);
       const requestData = {
        method: "post",
        url: `/admin/${url}/calendars/${id}`,
        data: {calendars:dates}
      };

      this.$http(requestData)
        .then(async (response) => {
          // console.log(response)
          let message = "تقویم با موفقیت ویرایش شد";
          if (this.editMode) message = "تقویم با موفقیت ویرایش شد";
          this.$bvToast.toast(message, {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
          this.showTop=false
        this.$emit('closeSideBar')
        this.selectedDate1=""
        this.selectedDate2=""
        this.selectedDates=[]
        })
        .catch((err) => {
          let message = "خطا در ویرایش تقویم ";
          if (this.editMode) message = "خطا در ویرایش تقویم ";
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;

          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
          });
          this.submitLoading = false;
          console.log(err);

        });
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: "Form Submitted",
      //     icon: "EditIcon",
      //     variant: "success",
      //   },
      // });
    },
    }

};
</script>

<style scoped>
.calendar-month {
  position: relative;
  background-color: var(--grey-200);
  border: solid 1px var(--grey-300);
}

.day-of-week {
  color: var(--grey-800);
  font-size: 18px;
  background-color: #fff;
  padding-bottom: 5px;
  padding-top: 10px;
}

.day-of-week,
.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.day-of-week > * {
  text-align: right;
  padding-right: 5px;
}

.days-grid {
  height: 100%;
  position: relative;
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
  border-top: solid 1px var(--grey-200);
}
</style>
