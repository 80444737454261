<template>
  <div class="calendar-date-indicator">{{ selectedMonth }}</div>
</template>

<script>
import moment from 'moment-jalaali';
export default {
  props: {
    selectedDate: {
      type: Object,
      required: true
    }
  },

  computed: {
    selectedMonth() {
      const gregorianDate = moment(`${this.selectedDate.jYear()}/${this.selectedDate.jMonth() + 1}/01`, 'jYYYY/jM/jD').format('MMMM YYYY');
      return gregorianDate;
    }
  }
};
</script>

<style scoped>
.calendar-date-indicator {
  font-size: 24px;
  font-weight: 600;
  color: var(--grey-00);
}
</style>

