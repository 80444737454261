<template>
  <div class="calendar-date-selector">
    <button @click="selectPrevious" class="btn-calender"><</button>
    <button @click="selectCurrent"  class="btn-calender">امروز</button>
    <button @click="selectNext"  class="btn-calender">></button>
  </div>
</template>

<script>

export default {
  name: "CalendarModeSelector",

  props: {
    currentDate: {
      type: String,
      required: true
    },

    selectedDate: {
      type: Object,
      required: true
    }
  },

  methods: {
    selectPrevious() {
      let newSelectedDate = this.$moment(this.selectedDate).subtract(1, "month");
      this.$emit("dateSelected", newSelectedDate);
    },

    selectCurrent() {
      let newSelectedDate = this.$moment(this.currentDate);
      this.$emit("dateSelected", newSelectedDate);
    },

    selectNext() {
      let newSelectedDate = this.$moment(this.selectedDate).add(1, "month");
      this.$emit("dateSelected", newSelectedDate);
    }
  }
};
</script>

<style scoped>
.calendar-date-selector {
  display: flex;
  justify-content: space-between;
  width: 80px;
  color: var(--grey-800);
}

.calendar-date-selector > * {
  cursor: pointer;
  user-select: none;
}
.btn-calender{
  padding: 5px 15px;
  border-radius: 4px;
  border: 1px solid #999;
}
</style>
